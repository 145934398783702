import { GET_TAGS, GET_TAGS_SUCCESS, GET_TAGS_ERROR } from './types';
import { axiosInstance } from '../utils/axiosInstance';
import { tagsConstants, shareConstants } from '../constants';

export const getOrganisationTags = () => async dispatch => {
  try {
    dispatch({ type: GET_TAGS });
    const { data } = await axiosInstance.get(tagsConstants.BASE_PATH);

    dispatch({ type: GET_TAGS_SUCCESS, payload: data.tags });
  } catch (error) {
    dispatch({
      type: GET_TAGS_ERROR,
      error,
    });
  }
};

export const getTagOptionsForUpload = (page = shareConstants.DEFAULT_PAGE, per_page = shareConstants.DEFAULT_PER_PAGE, q = null, callback) => {
  const params = { q, page, per_page };
  axiosInstance
    .get(tagsConstants.BASE_PATH, { params })
    .then(res => {
      const options = res.data.tags.map(tag => ({
        value: tag.name,
        label: tag.label,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

export const tagsActions = {
  getOrganisationTags,
  getTagOptionsForUpload,
};
